import { Injectable } from '@angular/core';
import { pick } from 'lodash';
import { firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { CompanyStateService } from 'src/app/auth/services/company-state.service';
import { ApiService } from './api.service';
import { NewAccount, User } from './user';
import { ApiResponse } from '../api';

@Injectable({
  providedIn: 'root'
})
export class AccountApiService extends ApiService<User> {

  static readonly USER_PROFILE_WRITABLE_FIELDS = [
    'firstName',
    'lastName',
    'companyRole',
    'mailSyncEnabled',
    'settings'
  ];
  static readonly USER_TERMS_WRITABLE_FIELDS = [
    'firstName',
    'lastName',
    'mailSyncEnabled'
  ];

  static readonly COMPANY_PROFILE_WRITABLE_FIELDS = [
    'name',
    'companyIndustry',
    'country',
    'province'
  ];

  constructor(
    protected httpClient: HttpClient,
    protected companyStateService: CompanyStateService
  ) {
    super(httpClient, companyStateService);
  }

  override getResourceUrl(): string {
    return '/v2/accounts';
  }

  agreeToTerms(username: string, profile: any): Promise<ApiResponse<Date>> {
    const fields = pick(profile, AccountApiService.USER_TERMS_WRITABLE_FIELDS);

    return firstValueFrom(super.requestAsObservable(null, 'PATCH', 'agree-to-terms', null, fields));
  }

  addAccount(profile: any, company: any): Promise<ApiResponse<NewAccount>> {
    const userFields = pick(profile, AccountApiService.USER_PROFILE_WRITABLE_FIELDS);
    const companyFields = pick(company, AccountApiService.COMPANY_PROFILE_WRITABLE_FIELDS);
    const fields = Object.assign({}, userFields, companyFields);

    return firstValueFrom(super.requestAsObservable(null, 'POST', '', null, fields));
  }

  getAccount(): Promise<User> {
    return firstValueFrom(super.requestAsObservable(null, 'GET', ''))
      .then(resp => resp.item);
  }
}
