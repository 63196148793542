import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { pick } from 'lodash';

import { CompanyStateService } from 'src/app/auth/services/company-state.service';
import { ApiService } from './api.service';
import { User } from './user';
import { ApiListResponse, ApiResponse } from '../api';
import { ApiSearch } from './api-utils.service';

@Injectable({
  providedIn: 'root'
})
export class UserApiService extends ApiService<User> {
  static readonly USER_WRITABLE_FIELDS = [
    'mailSyncEnabled', 'email', 'firstName', 'lastName', 'telephone',
    'roles', 'status', 'companyRole', 'dataCollectionDate', 'settings'
  ];

  static readonly BASIC_USER_WRITABLE_FIELDS = [
    'mailSyncEnabled', 'email', 'firstName', 'lastName', 'telephone',
    'status', 'companyRole', 'dataCollectionDate', 'settings'
  ];

  private _username: string;
  private _cachedPromise: Promise<any>;

  constructor(
    protected httpClient: HttpClient,
    protected companyStateService: CompanyStateService
  ) {
    super(httpClient, companyStateService);
  }

  override getResourceUrl(): string {
    return '/v2/users';
  }

  list(search: ApiSearch & { companyId?: string }, cursor?: string): Promise<ApiListResponse<User>> {
    let query = search.filter ? search.filter : '';
    if (search.query) {
      query += query ? ' AND (' + search.query + ')' : search.query;
    }

    const params = new HttpParams()
      .set('companyId', search.companyId)
      .set('search', query)
      .set('cursor', cursor)
      .set('count', search.count)
      .set('sort', search.sortBy + (search.reverse ? ' desc' : ' asc'));

    return firstValueFrom(super.listAsObservable(params));
  }

  getProfile(username: string, clearCache?: boolean): Promise<User> {
    if (username !== this._username || clearCache || !this._cachedPromise) {
      this._username = username;

      this._cachedPromise = this.get(username);
    }

    return this._cachedPromise;
  }

  get(username: string): Promise<User> {
    const params = new HttpParams()
      .set('username', username);

    return firstValueFrom(super.requestAsObservable(null, 'GET', 'by-username', params))
      .then(resp => resp.item);
  }

  update(username: string, profile: User, isUserAdmin: boolean): Promise<ApiResponse<User>> {
    const fields = pick(profile, isUserAdmin ? UserApiService.USER_WRITABLE_FIELDS : UserApiService.BASIC_USER_WRITABLE_FIELDS);
    let params = new HttpParams();

    if (username) {
      params = params.set('username', username);
    }

    return firstValueFrom(super.requestAsObservable(null, 'PATCH', '', params, fields));
  }

  add(companyId: string, username: string, profile: User): Promise<ApiResponse<User>> {
    const fields = pick(profile, UserApiService.USER_WRITABLE_FIELDS);

    const params = new HttpParams()
      .set('username', username)
      .set('companyId', companyId);

    return firstValueFrom(super.requestAsObservable(null, 'POST', '', params, fields));
  }

  delete(username: string): Promise<ApiResponse<User>> {
    return firstValueFrom(super.deleteAsObservable(username));
  }

  export(companyId: string): Promise<ApiResponse<string>> {
    const params = new HttpParams()
      .set('companyId', companyId);

    return firstValueFrom(super.requestAsObservable(null, 'GET', 'export', params));
  }
}
