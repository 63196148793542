import { Injectable } from '@angular/core';

import * as angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';

import { GapiLoader } from 'src/app/ajs-upgraded-providers';
import { UserauthApiService } from 'src/app/api/services/userauth-api.service';
import { UserStateService } from './user-state.service';

@Injectable({
  providedIn: 'root'
})
export class CustomAuthService {

  constructor(private gapiLoader: GapiLoader,
    private userauthApiService: UserauthApiService,
    private userStateService: UserStateService) { }

  authenticate() {
    var _state = this.userStateService._state;

    if (_state.userToken && _state.userToken.token) {
      this.userStateService.setAccessToken(_state.userToken.token);

      return this.gapiLoader().then((gApi) => {
        gApi.client.setToken(_state.userToken.token);

        // TODO: Validate token?

        return _state.userToken;
      });
    } else {
      return Promise.reject();
    }
  }

  _updateToken(username, loginInfo) {
    console.debug('JWT login result:', loginInfo);

    if (loginInfo && loginInfo.item) {
      return this.gapiLoader().then((gApi) => {
        var token = {
          access_token: loginInfo.item,
          expires_in: '3600',
          token_type: 'Bearer'
        };
        var userToken = {
          email: username,
          token: token
        };

        this.userStateService.setAccessToken(token);

        gApi.client.setToken(token);

        this.userStateService._state.userToken = userToken;

        return loginInfo.item;
      });
    } else {
      return Promise.reject('Invalid Auth Token (JWT)');
    }
  }

  login(credentials) {
    if (credentials && credentials.username && credentials.password) {
      return this.userauthApiService.login(credentials.username, credentials.password, credentials.token)
        .then((result) => {
          var loginInfo = result;

          return this._updateToken(credentials.username, loginInfo);
        });
    } else {
      return Promise.reject();
    }
  }

  addUser(credentials) {
    if (credentials && credentials.username && credentials.password) {
      return this.userauthApiService.add(credentials.username, credentials.password, credentials.hash)
        .then((result) => {
          var loginInfo = result;

          // Core's userauth.add returns a JWT token if the user is a Rise Test account
          if (loginInfo?.item) {
            return this._updateToken(credentials.username, loginInfo);
          } else {
            return null;
          }
        });
    } else {
      return Promise.reject();
    }
  }
}

angular.module('risevision.common.components')
  .factory('customAuthFactory', downgradeInjectable(CustomAuthService));