import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

import { StateService, UIRouterGlobals } from '@uirouter/angular';

import { UrlStateService, UserAuthFactory } from 'src/app/ajs-upgraded-providers';

import { ApiUtilsService } from 'src/app/api/services/api-utils.service';
import { CustomAuthService } from '../../services/custom-auth.service';
import { UserauthApiService } from 'src/app/api/services/userauth-api.service';
import { GoogleAuthService } from '../../services/google-auth.service';
import { RvshareAppDetectionService } from 'src/app/screen-sharing/services/rvshare-app-detection.service';
import { LocationHelperService } from 'src/app/shared/services/location-helper.service';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  credentials: any = {};
  messages: any = {};
  errors: any = {};
  ssoEnabled: boolean = false;
  ssoChecked: boolean = false;
  usePassword: boolean = false;
  originalEmail: string = '';
  loading: boolean = false;

  provision: boolean = false;
  SSO_LOGIN_URL = environment.SSO_LOGIN_URL;

  constructor(
    private sanitizer: DomSanitizer,
    private locationHelperService: LocationHelperService,
    private uiRouterGlobals: UIRouterGlobals,
    private apiUtils: ApiUtilsService,
    private userAuthFactory: UserAuthFactory,
    private googleAuthService: GoogleAuthService,
    private customAuthService: CustomAuthService,
    private urlStateService: UrlStateService,
    private userauth: UserauthApiService,
    protected rvshareAppDetectionService: RvshareAppDetectionService,
    private $state: StateService
  ) { }

  ngOnInit(): void {
    this.loading = false;
    this.messages.passwordReset = this.uiRouterGlobals.params.passwordReset;

    if (this.uiRouterGlobals.params.user && this.uiRouterGlobals.params.token) {
      this.credentials.username = this.uiRouterGlobals.params.user;
      this.credentials.token = this.uiRouterGlobals.params.token;
    }

    if (this.uiRouterGlobals.params.authError && this.uiRouterGlobals.params.authError !== 'No user') {
      this._processErrorCode(this.uiRouterGlobals.params.authError, 'in');
    }

    if (this.uiRouterGlobals.params.provision) {
      this.provision = true;
      this.credentials.companyId = this.uiRouterGlobals.params.cid;
    }
  }

  private _processErrorCode(e: any, actionName: string): void {
    var error = this.apiUtils.getError(e);
    this.errors.messageTitle = 'Something went wrong.';
    var message = error.message ? error.message :
      'Please try again or <a target="_blank" href="mailto:support@risevision.com">reach out to our Support team</a> if the problem persists.';

    if (e && e.status >= 400 && e.status < 500) {
      this.errors.genericError = true;
    } else if (e && (e.status === -1 || error.code === -1 || error.code === 0)) {
      this.errors.message = 'Please check your connection, or proxy and firewall settings, and try again.';
    } else if (e && e.code === 403 && message.indexOf('feature is not available') !== -1) {
      this.errors.message = 'Please upgrade your account to access Rise Vision with the Teacher user role.';
    } else if (e && e.type === 'sso_error') {
      switch (message) {
        case 'No custom attributes':
          this.errors.message = 'You do not have any custom attributes through your IdP.';
          break;
        case 'Invalid user roles':
          this.errors.message = 'You were assigned invalid user roles through your IdP.';
          break;
        case 'No user roles':
          this.errors.message = 'You do not have an assigned user role through your IdP.';
          break;
        case 'User provisioning failure':
          this.errors.message = 'Your account provisioning failed.';
          break;
        default:
          this.errors.message = this.sanitizer.bypassSecurityTrustHtml(message);
      }

      this.errors.message += ' Please contact your system administrator.';
    } else {
      // Catch all errors including !e, e.status === 500, e.status === 503, etc
      this.errors.message = this.sanitizer.bypassSecurityTrustHtml(message);
    }
  }

  googleLogin(): void {
    this.loading = true;

    this.googleAuthService.forceAuthenticate();
  }

  private _authenticate(): Promise<void> {
    this.userAuthFactory.setLoginMethod('Rise User Auth');

    return this.userAuthFactory.authenticate(true)
      .then(() => {
        this.urlStateService.redirectToState(this.uiRouterGlobals.params.state);
      });
  }

  private _customLogin(): void {
    this.customAuthService.login(this.credentials)
      .then(this._authenticate.bind(this))
      .catch((err) => {
        if (err && err.status === 400) {
          if (err.result?.error?.message === 'SSO login required') {
            this.messages.ssoRequired = true;
          } else {
            this.messages.isGoogleAccount = true;
          }
        } else if (err && err.status === 403) {
          this.errors.userAccountLockoutError = true;
        } else if (err && err.status === 409) {
          if (err.result?.error?.message === 'Invalid confirmation token') {
            this.errors.invalidConfirmationTokenError = true;
          } else if (err.result?.error?.message === 'User email not confirmed') {
            this.errors.userEmailNotConfirmedError = true;
          } else {
            this.errors.termsNotAcceptedError = true;
          }
        } else { // No special case for 404, for security reasons
          console.error(err);
          this._processErrorCode(err, 'in');
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }

  private _ssoLoginRedirect(): void {
    this.locationHelperService.setHref(this.SSO_LOGIN_URL +
      '?id=' + encodeURIComponent(this.credentials.username) +
      '&cid=' + (this.credentials.companyId ? encodeURIComponent(this.credentials.companyId) : '') +
      '&url=' + window.location.origin);
  }

  checkSso(): Promise<void> {
    const promise = this.provision ? this.userauth.canProceedWithSSO(this.credentials.username, this.credentials.companyId) :
      this.userauth.isSsoEnabled(this.credentials.username);

    return promise
      .then((resp) => {
        this.ssoEnabled = resp?.item;
        this.originalEmail = this.credentials.username;

        if (!this.provision) {
          this.ssoChecked = true;
        } else {
          if (this.ssoEnabled) {
            this._ssoLoginRedirect();
          } else {
            this.messages.invalidSso = true;
          }
        }
      })
      .catch((err) => {
        console.error(err);
        this._processErrorCode(err, 'in');
      });
  }

  ssoLogin(loginForm: NgForm): void {
    this.errors = {};
    this.messages = {};

    if (loginForm.valid) {
      this.loading = true;
      if (!this.ssoChecked) {
        this.checkSso().then(() => {
          this.loading = false;
        });
      } else {
        if (this.credentials.username !== this.originalEmail) {
          this.checkSso().then(() => {
            if (!this.ssoEnabled && this.credentials.password) {
              this._customLogin();
            } else {
              this.loading = false;
            }
          });
        } else if (this.ssoEnabled && !this.usePassword) {
          this._ssoLoginRedirect();
          setTimeout(() => {
            loginForm.resetForm();
          });
        } else {
          this._customLogin();
        }
      }
    }
  }

  goToJoin(rvshare?: boolean) {
    this.$state.go('apps.screen-sharing.home', { rvshare });
  }
}
